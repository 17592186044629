<template>
  <section class="section section-hero section-hero-services">
    <div class="section-hero-bg">
      <section-title
        icon="icon-products"
        h2="Dobozos termékeinkkel fejlesztései <br class='d-none d-lg-block'> könnyebben és gyorsabban megvalósulhatnak."
        h2small="2021-ben az internetes jelenlét szükségszerű"
        createdate=""
      />
    </div>
    <div class="section section-hero-body">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 order-2 order-lg-1">
            <small class="h2-small">
              2008-tól, a Magento e-commerce megjelenésével, időnket a Magento
              webáruház motor megismerésére fordítotjuk. A legsikeresebb
              termékünk a folyamatosan frissülő és karbantartott magyar nyelvi
              csomag Magento Open Source 1.x webáruházakhoz.
            </small>

            <div class="box-contents">
              <h3>Magento 1.x magyar nyelvi csomag</h3>
              <div id="bovebben-a-magento-1x-magyar-nyelvi-csomagrol"></div>
              <br />

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#bovebben-a-magento-1x-magyar-nyelvi-csomagrol"
              >
                <span>// Bővebben a Magento 1.x magyar nyelvi csomagról</span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#csomagok-tipusok"
              >
                <span>// Elérhető csomagok, típusok</span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#6-erv-a-magento-1x-magyar-csomag-mellett"
              >
                <span>// 6 érv a Magento 1.x magyar csomag mellett</span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link btn-link-cta"
                to="#megrendeles"
              >
                <span># Megrendelés!</span>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-lg-6 order-1 order-lg-2">
            <div class="col-6 col-lg-12 m-auto mb-3 mb-lg-0">
              <img
                src="@/assets/img/all/img-box-products-magento-1x-right.png"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-products"
    h2="Bővebben a Magento 1.x magyar nyelvi csomagról"
    h2small="A magyar nyelvi csomag segítségével a Magento Open Source 1.x webáruház <br class='d-none d-lg-block'> könnyen és egyszerűen adaptálható a magyarországi kultúrális környezetbe."
    createdate=""
  />

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3>// Bevezető</h3>
          <p class="lead">
            Az internacionalizálás és a lokalizáció olyan módszerek, melyekkel a
            Magento webáruház adaptálható más országokba vagy kultúrákba, azaz
            az amerikaitól eltérő környezetbe.
          </p>
          <div class="framed">
            <p class="lead">
              <strong>Internacionalizálás (i18n):</strong> Építészeti, mérnöki
              gyakorlatok és megközelítések összessége a szoftverek építéséhez.
              Speciális regionális követelmények kódban történő elhelyezése. A
              rövidítés az angol internationalization szóból ered (az első és
              utolsó betű között 18 másik betű áll).
            </p>
            <p class="lead">
              <strong>Lokalizáció (l10n):</strong> A kulturális beágyazás, vagy
              nyelvi lokalizáció egy adott termék új piacon történő
              bevezetésének egyik fázisa, a globalizációs folyamat második
              lépése, melynek során a terméket az új piac (ország, régió,
              lakosság) normáihoz, igényeihez, elvárásaihoz igazítjuk.
              <br />
              A kulturális beágyazás vagy nyelvi lokalizáció nem pusztán
              fordítási folyamat: a termék és az ahhoz kapcsolódó dokumentáció
              fordításán túl megköveteli a piaci különbségek és a célkultúra
              alapos ismeretét, mely elengedhetetlen ahhoz, hogy a „lokalizálni”
              kívánt termék a helyi igényeknek eleget tehessen. A nyelvi
              lokalizáció nemzetközileg használatos rövidítése: L10N (az „L” és
              „N” közötti tíz betűt helyettesítik számmal).
            </p>
          </div>
          <p class="lead">
            Az internacionalizálási és honosítási munkák legfőbb pontjai közé
            tartoznak az alábbiak: - dátum-/időformátum (nemzetközi
            környezetekben UTC), <br />
            - pénznem, <br />
            - nyelv (ábécék, számok és balról jobbra, illetve jobbról balra író
            nyelvek; Unicode), <br />
            - a vallási vagy más érzékenységeket sértő kifejezések figyelembe
            vétele, <br />
            - nevek és címek, <br />
            - telefonszámok, postai címek és nemzetközi irányítószámok, <br />
            - mértékegységek.
          </p>
          <p class="lead">
            Egy webáruháznál az internacionalizáció és a honosítás nehéz
            feladat, főleg akkor, ha a szoftvert csak egy piacra szánták. A
            Magento már a kezdetektől - és ezzel már a kezdetektől erősen
            túlmutatott a versenytársakon - nagyon odafigyelt a különböző
            lokalizációs beállításokra. <br />
            Ennek jelentős részét képezte a fordíthatóság, azaz, hogy a
            webáruházban és az adminisztrációs felületen is minden megjelenő
            szöveg fordítható állapotba került a forrásba és innentől már csak a
            fejlesztőkön múlott, hogy mit kezdenek ezzel a lehetőséggel. Mivel a
            Magento egy nyílt forráskódú szoftver, szabadon módosítható és
            forgalmazható, jóval alkalmasabb is az internacionalizálásra, mint a
            néhány fő által létrehozott, zárt rendszerek azaz az egyedi
            fejlesztésű webáruházak.
          </p>

          <h3>// Magyarország hátrányos helyzete</h3>
          <p>
            1. Társalgási szinten a magyarok csak 20%-a beszél angolul és a
            magyar emberek 65%-a semmilyen idegen nyelven nem tud kommunikálni.
            Ez a legrosszabb teljesítmény az Európai Unióban. Ne veszítsen
            potenciális vásárlókat, csak azért, mert nem magyar nyelvű vagy
            magyartalan a Magento webáruháza! Nem lehet elégszer hangsúlyozni,
            hogy az itt-ott megjelenő angol szavak vagy mondatok mennyire
            zavaróak lehetnek (főleg az idősebb korosztálynak) illetve mennyire
            hanyag, nemtörődöm benyomást keltenek az oldal látogatóiban. Egy
            igényes webáruház ez nem engedheti meg magának!
          </p>
          <p>
            2. A Magento alapvetően amerikai software, annak ellenére, hogy a
            fejlesztők és a vezetők között igen sok nemzet képviselteti magát.
            Így a magyar specialitások (főleg a nyelvi fordulatok) nem minden
            esetben valósíthatóak meg tökéletesen.
          </p>
          <p>
            3. Mivel alapvetően kicsi a piac, így az esetlegesen megjelenő hibák
            vagy tényleg magyar specialitások nehezen kerülnek rivalda fénybe,
            így legtöbbször nekünk kell kikutatnunk a megoldást (bár ez akár
            előny is lehet, ha valaki szeret magától rájönni a megoldásokra).
          </p>
          <p>
            4. A magyar az egyik legnehezebben elsajátítható nyelv, olyan
            speciális nyelvtani szabályokkal (például: ragozás), amelyeket a
            magyar anyanyelvűek napi szinten használnak viszont aki tanulja
            ezeket csak hosszú idő után tudja alkalmazni őket.
          </p>
          <p id="csomagok-tipusok"></p>
          <p class="lead text-justify">
            A Magento Open Source 1.x keretrendszer a megvásárolt magyar nyelvi
            csomaggal kibővítve a Magento alapú webáruházának adminisztrációs
            felülete, a vevők által látogatott fő- és aloldalak valamint az
            emailekben történő kommunikáció magyar nyelven történhet.
          </p>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-products"
    h2="Elérhető csomagok, típusok"
    h2small="A magyar nyelvi csomagok megjelenési időpontjai, verziói"
    createdate=""
  />

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-10 offset-lg-1 text-center">
          <table class="table table-hover table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col" class="text-uppercase"><p>Dátum</p></th>
                <th scope="col" class="text-uppercase"><p>Verzió</p></th>
                <th scope="col" class="text-uppercase"><p>Leírás</p></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <p class="lead text-center">
                    2019.06.05.
                    <span class="badge-danger text-center">ÚJ!</span>
                  </p>
                </th>
                <td>
                  <p class="lead text-center">Magento 1.9.4.2 Open Source</p>
                </td>
                <td><p class="lead text-center">magázó, bővített</p></td>
              </tr>
              <tr>
                <th scope="row"><p>2019.03.31.</p></th>
                <td>
                  <p>Magento 1.9.4.1 Open Source</p>
                </td>
                <td><p>magázó, bővített</p></td>
              </tr>
              <tr>
                <th scope="row"><p>2019.01.03.</p></th>
                <td>
                  <p>Magento 1.9.4.0 Open Source</p>
                </td>
                <td><p>magázó, bővített</p></td>
              </tr>
              <tr>
                <th scope="row"><p>2018.09.20.</p></th>
                <td>
                  <p>Magento 1.9.3.10 Open Source</p>
                </td>
                <td><p>magázó, bővített</p></td>
              </tr>
              <tr>
                <th scope="row"><p>2018.07.03.</p></th>
                <td>
                  <p>Magento 1.9.3.9 Open Source</p>
                </td>
                <td><p>magázó, bővített</p></td>
              </tr>
              <tr>
                <th scope="row"><p>2017.04.23.</p></th>
                <td>
                  <p>Magento 1.9.3.2 Open Source</p>
                </td>
                <td><p>magázó, bővített</p></td>
              </tr>
              <tr>
                <th scope="row"><p>2017.01.02.</p></th>
                <td>
                  <p>Magento 1.9.3.1 Open Source</p>
                </td>
                <td><p>magázó, bővített</p></td>
              </tr>
              <tr>
                <th scope="row"><p>2016.11.10.</p></th>
                <td>
                  <p>Magento 1.9.3.0 Open Source</p>
                </td>
                <td><p>magázó, bővített</p></td>
              </tr>
              <tr>
                <th scope="row"><p>2016.03.14.</p></th>
                <td>
                  <p>Magento 1.9.2.4 Open Source</p>
                </td>
                <td><p>magázó, bővített</p></td>
              </tr>
              <tr>
                <th scope="row"><p>2016.02.01.</p></th>
                <td>
                  <p>Magento 1.9.2.3 Open Source</p>
                </td>
                <td><p>magázó, bővített</p></td>
              </tr>
              <tr>
                <th scope="row"><p>2015.11.01.</p></th>
                <td>
                  <p>Magento 1.9.2.2 Open Source</p>
                </td>
                <td><p>magázó, bővített</p></td>
              </tr>
              <tr>
                <th scope="row"><p>2015.09.01.</p></th>
                <td>
                  <p>Magento 1.9.0.1 Open Source</p>
                </td>
                <td><p>magázó, bővített</p></td>
              </tr>
              <tr>
                <th scope="row"><p>2015.06.01.</p></th>
                <td>
                  <p>Magento 1.9.0.0 Open Source</p>
                </td>
                <td><p>magázó, bővített</p></td>
              </tr>
              <tr>
                <th scope="row"><p>2014.01.01.</p></th>
                <td>
                  <p>Magento 1.8.0.0 Open Source</p>
                </td>
                <td><p>magázó, bővített</p></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-products"
    h2="6 érv a Magento 1.x magyar csomag mellett"
    h2small="A magyar nyelvi csomag segítségével a Magento Open Source 1.x webáruház könnyen adaptálható a magyarországi kultúrális környezetbe."
    createdate=""
  />

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4 text-center mb-lg-5 mb-0">
          <img
            src="@/assets/img/all/icon-products-magento-61.svg"
            alt=""
            class="img-fluid"
          />
          <br />
          <h3 class="mt-4">// Robosztus</h3>
          <p class="lead">
            Több, mint 6.000 sornyi M1.x fordítást tartalmaz a csomag (a
            keretrendszerben fordítható formában megtalálható szövegek valamint
            a kereskedelmi emailek), amely tudomásunk szerint a jelenleg
            elérhető legteljesebb fordítás Magento Open Source webáruházhoz
            magyar nyelven.
          </p>
        </div>

        <div class="col-12 col-lg-4 text-center mb-lg-5 mb-0">
          <img
            src="@/assets/img/all/icon-products-magento-62.svg"
            alt=""
            class="img-fluid"
          />
          <br />
          <h3 class="mt-4">// Minőségi</h3>
          <p class="lead">
            A fordítást olyan szakemberek végezték, akik dolgoztak már Magento
            alapú webáruházzal és így a magyar nyelvben gyakran használatos
            kifejezéseket és szófordulatokat használták, a tükör- és az
            automatikus fordításokat kerülték. A lehető legnagyobb gondossággal
            jártak el, hogy kiemelkedő végeredmény születhessen.
          </p>
        </div>
        <div class="col-12 col-lg-4 text-center mb-lg-5 mb-0">
          <img
            src="@/assets/img/all/icon-products-magento-63.svg"
            alt=""
            class="img-fluid"
          />
          <br />
          <h3 class="mt-4">// Garanciális</h3>
          <p class="lead">
            Bízunk a saját termékünkben. Minket igazol az a tény is, hogy eddig
            még senki nem kívánta a garanciát érvényesíteni. Azonban, ha ön
            mégis elégedetlen termékünkkel, akkor a vásárlástól számított 8
            napon belül jelezze és ne használja tovább a nyelvi csomagot. A
            termék árát pedig kérdések nélkül visszakapja 15% kezelési költség
            levonása után.
          </p>
        </div>
        <div class="col-12 col-lg-4 text-center">
          <img
            src="@/assets/img/all/icon-products-magento-64.svg"
            alt=""
            class="img-fluid"
          />
          <br />
          <h3 class="mt-4">// Naprakész</h3>
          <p class="lead">
            Amint új, hivatalos Magento 1.x verzió kerül kiadásra, igyekszünk a
            lehető legrövidebb idővel reagálni és elkészíteni hozzá a Magento
            1.x magyar nyelvi csomagokat. Partnereink visszajelzései illetve a
            meglévő webáruházak monitorozása alapján is szükség esetén
            módosítjuk a tartalmukat.
          </p>
        </div>
        <div class="col-12 col-lg-4 text-center">
          <img
            src="@/assets/img/all/icon-products-magento-65.svg"
            alt=""
            class="img-fluid"
          />
          <br />
          <h3 class="mt-4">// Karbantartott</h3>
          <p class="lead">
            Tökéletes munkára törekszünk: így a csomagokat folyamatosan
            frissítjük, karbantartjuk. Számítunk minden építő jellegű
            visszajelzésre, kritikára, javaslatra és természetesen megfontoljuk
            azokat. Ha hibát, elgépelést talált, akkor kérjük jelezze és 3
            munkanapon belül küldjük a javított verziót, természetesen - ahogy
            igértük - ingyenesen.
          </p>
        </div>
        <div class="col-12 col-lg-4 text-center">
          <img
            src="@/assets/img/all/icon-products-magento-66.svg"
            alt=""
            class="img-fluid"
          />
          <br />
          <h3 class="mt-4">// Bővített</h3>
          <p class="lead">
            A Magento webáruház alap rendszerében megtalálható sorok mellett
            valamint a kereskedelmi emaileken túl minden olyan szöveg fordítását
            igyekeztünk a csomagokban elhelyezni, amelyek a fordítási fájlokból
            módosíthatóak. Tettük ezt azokkal a szavakkal, mondatokkal is,
            amelyeket az alap fordítási fájl nem tartalmaz.
          </p>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Technológiák, amiket használunk"
    h2small=""
    createdate=""
  />

  <section class="section section-body section-body-icons">
    <div class="container">
      <div class="row">
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-magento.svg" alt="MAGENTO" />
          <h3 class="mt-2"># MAGENTO</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-html5.svg" alt="HTML5" />
          <h3 class="mt-2"># HTML</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-css3.svg" alt="CSS3" />
          <h3 class="mt-2"># CSS</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-scss.svg" alt="SCSS" />
          <h3 class="mt-2"># SCSS</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-javascript.svg" alt="JavaScript" />
          <h3 class="mt-2"># JavaScript</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-sketch.svg" alt="Sketch" />
          <h3 class="mt-2"># Sketch</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-photoshop.svg" alt="Photoshop" />
          <h3 class="mt-2"># Photoshop</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-mysql.svg" alt="Mysql" />
          <h3 class="mt-2"># Mysql</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-php.svg" alt="Php" />
          <h3 class="mt-2"># php</h3>
        </div>
        <div class="d-none d-lg-block col-4 col-lg-2 mt-3 text-center">
          <img
            src="@/assets/img/all/icon-vscode.svg"
            alt="Visual Studio Code"
          />
          <h3 class="mt-2"># VS Code</h3>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-products"
    h2="Néhány képernyőkép a Magento Open Source 1.x magyar nyelvi csomagról"
    h2small="A screenshotok egy teszt oldalról készültek."
    createdate=""
  />

  <ModalScreenshots id="Magento1-01" products="m1" img="01"></ModalScreenshots>
  <ModalScreenshots id="Magento1-02" products="m1" img="02"></ModalScreenshots>
  <ModalScreenshots id="Magento1-03" products="m1" img="03"></ModalScreenshots>
  <ModalScreenshots id="Magento1-04" products="m1" img="04"></ModalScreenshots>
  <ModalScreenshots id="Magento1-05" products="m1" img="05"></ModalScreenshots>
  <ModalScreenshots id="Magento1-06" products="m1" img="06"></ModalScreenshots>
  <ModalScreenshots id="Magento1-07" products="m1" img="07"></ModalScreenshots>
  <ModalScreenshots id="Magento1-08" products="m1" img="08"></ModalScreenshots>
  <ModalScreenshots id="Magento1-09" products="m1" img="09"></ModalScreenshots>
  <ModalScreenshots id="Magento1-10" products="m1" img="10"></ModalScreenshots>
  <ModalScreenshots id="Magento1-11" products="m1" img="11"></ModalScreenshots>
  <ModalScreenshots id="Magento1-12" products="m1" img="12"></ModalScreenshots>

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-3">
          <a
            href="@/assets/img/products/m1-screenshot-01.png"
            data-bs-toggle="modal"
            data-bs-target="#Magento1-01"
          >
            <img
              src="@/assets/img/products/m1-screenshot-01_t.jpg"
              alt="Magento Open Source 1.x screenshot"
              class="thumbnail img-fluid mb-5"
            />
          </a>
        </div>
        <div class="col-12 col-lg-3">
          <a
            href="@/assets/img/products/m1-screenshot-02.png"
            data-bs-toggle="modal"
            data-bs-target="#Magento1-02"
          >
            <img
              src="@/assets/img/products/m1-screenshot-02_t.jpg"
              alt="Magento Open Source 1.x screenshot"
              class="thumbnail img-fluid mb-5"
            />
          </a>
        </div>
        <div class="col-12 col-lg-3">
          <a
            href="@/assets/img/products/m1-screenshot-03.png"
            data-bs-toggle="modal"
            data-bs-target="#Magento1-03"
          >
            <img
              src="@/assets/img/products/m1-screenshot-03_t.jpg"
              alt="Magento Open Source 1.x screenshot"
              class="thumbnail img-fluid mb-5"
            />
          </a>
        </div>
        <div class="col-12 col-lg-3">
          <a
            href="@/assets/img/products/m1-screenshot-04.png"
            data-bs-toggle="modal"
            data-bs-target="#Magento1-04"
          >
            <img
              src="@/assets/img/products/m1-screenshot-04_t.jpg"
              alt="Magento Open Source 1.x screenshot"
              class="thumbnail img-fluid mb-5"
            />
          </a>
        </div>
        <div class="col-12 col-lg-3">
          <a
            href="@/assets/img/products/m1-screenshot-05.png"
            data-bs-toggle="modal"
            data-bs-target="#Magento1-05"
          >
            <img
              src="@/assets/img/products/m1-screenshot-05_t.jpg"
              alt="Magento Open Source 1.x screenshot"
              class="thumbnail img-fluid mb-5"
            />
          </a>
        </div>
        <div class="col-12 col-lg-3">
          <a
            href="@/assets/img/products/m1-screenshot-06.png"
            data-bs-toggle="modal"
            data-bs-target="#Magento1-06"
          >
            <img
              src="@/assets/img/products/m1-screenshot-06_t.jpg"
              alt="Magento Open Source 1.x screenshot"
              class="thumbnail img-fluid mb-5"
            />
          </a>
        </div>
        <div class="col-12 col-lg-3">
          <a
            href="@/assets/img/products/m1-screenshot-07.png"
            data-bs-toggle="modal"
            data-bs-target="#Magento1-07"
          >
            <img
              src="@/assets/img/products/m1-screenshot-07_t.jpg"
              alt="Magento Open Source 1.x screenshot"
              class="thumbnail img-fluid mb-5"
            />
          </a>
        </div>
        <div class="col-12 col-lg-3">
          <a
            href="@/assets/img/products/m1-screenshot-08.png"
            data-bs-toggle="modal"
            data-bs-target="#Magento1-08"
          >
            <img
              src="@/assets/img/products/m1-screenshot-08_t.jpg"
              alt="Magento Open Source 1.x screenshot"
              class="thumbnail img-fluid mb-5"
            />
          </a>
        </div>
        <div class="col-12 col-lg-3">
          <a
            href="@/assets/img/products/m1-screenshot-09.png"
            data-bs-toggle="modal"
            data-bs-target="#Magento1-09"
          >
            <img
              src="@/assets/img/products/m1-screenshot-09_t.jpg"
              alt="Magento Open Source 1.x screenshot"
              class="thumbnail img-fluid mb-5"
            />
          </a>
        </div>
        <div class="col-12 col-lg-3">
          <a
            href="@/assets/img/products/m1-screenshot-10.png"
            data-bs-toggle="modal"
            data-bs-target="#Magento1-10"
          >
            <img
              src="@/assets/img/products/m1-screenshot-10_t.jpg"
              alt="Magento Open Source 1.x screenshot"
              class="thumbnail img-fluid mb-5"
            />
          </a>
        </div>
        <div class="col-12 col-lg-3">
          <a
            href="@/assets/img/products/m1-screenshot-11.png"
            data-bs-toggle="modal"
            data-bs-target="#Magento1-11"
          >
            <img
              src="@/assets/img/products/m1-screenshot-11_t.jpg"
              alt="Magento Open Source 1.x screenshot"
              class="thumbnail img-fluid mb-5"
            />
          </a>
        </div>
        <div class="col-12 col-lg-3">
          <a
            href="@/assets/img/products/m1-screenshot-12.png"
            data-bs-toggle="modal"
            data-bs-target="#Magento1-12"
          >
            <img
              src="@/assets/img/products/m1-screenshot-12_t.jpg"
              alt="Magento Open Source 1.x screenshot"
              class="thumbnail img-fluid mb-5"
            />
          </a>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-products"
    h2="Hogyan telepíthető a nyelvi csomag?"
    h2small="Magento Open Source 1.x magyar nyelvi csomag telepítésének ismertetése"
    createdate=""
  />

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="ratio ratio-16x9">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/2EGYOZacbNY"
              allowfullscreen=""
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div id="megrendeles"></div>
  </section>

  <SectionTitle
    icon="icon-products"
    h2="Árak, megrendelés"
    h2small="Kedvező árakkal, egyszerű fizetési megoldásokkal juthat hozzá <br class='d-none d-lg-block'> a Magento 1.x magyar nyelvi csomaghoz"
    createdate=""
  />

  <section v-if="sent" class="section section-price-box mt-5 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="lead text-center">
            Köszönjük a megrendelést, hamarosan jelentkezünk!
          </p>
        </div>

        <div class="col-12 text-center">
          <router-link
            @click="hidden = !hidden"
            class="btn pt-4 pb-4 mt-3 mb-5"
            to="/termekeink/magento-1-magyar-nyelvi-modul"
          >
            <span class="ps-5 pe-5">Új megrendelés? </span>
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <section v-if="!sent" class="section section-price-box mt-5 mb-5">
    <div class="container">
      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice30"
            data-bs-target="#collapseprice30"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Licencek száma (db)</h5>
                <p class="collapse show" id="collapseprice30">
                  1db licenc 1db webáruházhoz kapcsolódik. Egy webáruháznak
                  minősül az is, ha több szerveren (dev, stage, local) folyik a
                  fejlesztés.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center w-100"
          >
            <h4 class="w-75">
              <input
                type="range"
                class="form-range"
                min="1"
                max="20"
                id="range"
                v-model="range"
                v-if="!showform"
              />
              {{ range }}
              <span>db</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice40"
            data-bs-target="#collapseprice40"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />

                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Ingyenes frissítés (nap)</h5>
                <p class="collapse show" id="collapseprice40">
                  Az ingyenes támogatási időszakban (90nap, 120nap, 180nap)
                  megjelenő újabb verziókat ingyenesen biztosítjuk. A kezdő
                  időpont a digitálisan elküldött csomag időpillanata.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <h4>
              {{ freedays }}
              <span>nap</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice50"
            data-bs-target="#collapseprice50"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Kedvezmény kupon (%)</h5>
                <p class="collapse show" id="collapseprice50">
                  A csomagnak megfelelő kedvezmény (10%, 20%, 30%) kupon 90
                  napon belül használható fel újabb termék vagy szolgáltatás
                  megrendelése esetén.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <h4>
              {{ coupon }}
              <span>%</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice90"
            data-bs-target="#collapseprice90"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />

                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Online konzultáció (igen/nem)</h5>
                <p class="collapse show" id="collapseprice90">
                  Ha úgy érzi, hogy szüksége van bővebb tájékoztatásra akkor a
                  válaszott online platformon bővebb tájékoztatást nyújtunk,
                  külön díj ellenében (7620 Ft), a landing oldal készítéssel
                  kapcsolatban (30perc).
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioOnline"
                id="option0-online"
                value="false"
                v-model="online"
              />
              <label class="btn pt-3 pb-3" for="option0-online">
                <span class="ps-5 pe-5"> nem </span>
              </label>
            </div>
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioOnline"
                id="option1-online"
                value="true"
                v-model="online"
              />
              <label class="btn pt-3 pb-3" for="option1-online">
                <span class="ps-5 pe-5"> igen </span>
              </label>
            </div>

            <div v-if="showform">
              <h4 v-if="!online">nem</h4>
              <h4 v-if="online">igen</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice100"
            data-bs-target="#collapseprice100"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Ár (Ft)</h5>
                <p class="collapse show" id="collapseprice100">
                  Az árak a 27%-s áfát tartalmazzák.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <h4>{{ totalpricecalculate }} Ft</h4>
          </div>
        </div>
      </div>

      <div v-if="!showform" class="row equal-cols">
        <div class="col price-col price-col-1"></div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <button @click="showform = true" class="btn btn-send pt-4 pb-4">
              <span class="ps-4 pe-4 text-center">
                Magento 1.x nyelvi csomag megrendelése
              </span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="showform" class="row">
        <div class="d-none d-lg-block col-lg-2 price-col-1"></div>
        <div class="col-12 col-lg-8 price-col-1">
          <Form
            v-if="!sent"
            @submit="sendForm"
            :validation-schema="schema"
            v-slot="{ errors }"
            class="contact-us-form"
          >
            <div class="form-group">
              <label for="name">// Cégnév, kapcsolattartó</label>
              <Field hidden name="licences" :value="this.range" />
              <Field hidden name="freedays" :value="this.freedays" />
              <Field hidden name="coupon" :value="this.coupon" />
              <Field hidden name="online" :value="this.online" />
              <Field hidden name="when" :value="this.when" />
              <Field hidden name="totalprice" :value="totalpricecalculate" />
              <Field
                name="name"
                as="input"
                placeholder="Kérjük, adja meg cégnevét és a kapcsolattartó nevét!"
                class="form-control"
                :class="{ 'is-invalid': errors.name }"
              />
              <div class="invalid-feedback">{{ errors.name }}</div>
            </div>

            <div class="form-group">
              <label for="email">// Email cím</label>
              <Field
                name="email"
                as="input"
                placeholder="Kérjük, adja meg email címét!"
                class="form-control"
                :class="{ 'is-invalid': errors.email }"
              />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>

            <div class="form-group">
              <label for="phone_number">// Telefonszám</label>
              <Field
                name="phone_number"
                as="input"
                placeholder="Kérjük, adja meg telefonszámát!"
                class="form-control"
                :class="{ 'is-invalid': errors.phone_number }"
              />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>

            <div class="form-group">
              <label for="when">// Mikor keressük telefonon?</label>
              <Field
                name="when"
                as="select"
                class="form-control"
                :class="{ 'is-invalid': errors.service }"
              >
                <option value="" disabled selected>
                  Kérjük, adja meg mikor kereshetjük telefonon?
                </option>
                <option value="9-12">9-12h</option>
                <option value="12-16">12-16h</option>
                <option value="Ne keressenek">
                  Nem kérek telefonos hívást
                </option>
              </Field>
              <div class="invalid-feedback">{{ errors.service }}</div>
            </div>

            <div class="form-group">
              <label for="message">// Üzenet</label>
              <Field
                name="message"
                as="textarea"
                placeholder="Üzenet"
                class="form-control form-message"
                value=""
                :class="{ 'is-invalid': errors.message }"
              />
              <div class="invalid-feedback">{{ errors.message }}</div>
            </div>

            <div class="form-check">
              <Field
                name="accept"
                type="checkbox"
                id="accept"
                value="true"
                class="form-check-input custom-control-input"
                :class="{ 'is-invalid': errors.accept }"
              />
              <label for="accept" class="form-check-label custom-control-label">
                Megismertem és elfogadom az <br />
                <router-link
                  @click="hidden = !hidden"
                  class="btn-privacy-link"
                  to="/adatvedelmi-tajekoztato"
                >
                  <span>adatvédelmi nyilatkozatot</span>
                </router-link>
              </label>
            </div>

            <div class="form-group text-center mt-5">
              <div class="d-grid col-lg-8 m-auto">
                <button type="submit" class="btn pt-4 pb-4 mb-2">
                  <span>Üzenet elküldése</span>
                </button>
                Minden mező kitöltése kötelező. <br />
                A megadott adatokat csak és kizárólag a kapcsolatfelvételhez
                használjuk fel.
              </div>
            </div>
          </Form>
        </div>
        <div class="d-none d-lg-block col-lg-2 price-col-1"></div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import ModalScreenshots from "../../components/ModalScreenshots.vue";

export default {
  components: {
    SectionTitle,
    ModalScreenshots,
    Form,
    Field,
  },
  data() {
    const schema = Yup.object().shape({
      name: Yup.string().required("A név kötelező!"),
      email: Yup.string()
        .required("Az email kötelező!")
        .email("Az email érvénytelen!"),
      phone_number: Yup.string().required("A telefonszám kötelező!"),
      when: Yup.string().required("Kötelező mező!"),
      message: Yup.string().required(
        "Legalább 100 karakter üzenet írása kötelező!"
      ),
      accept: Yup.string().required(
        "Fogadja el az adatkezelési irányelveinket!"
      ),
    });

    return {
      range: 1,
      price13: 19900,
      price49: 17990,
      price1020: 11990,
      design: false,
      online: false,
      onlineprice: 7620,
      showform: false,
      schema,
      sent: false,
    };
  },

  computed: {
    freedays() {
      let days = 90;
      if (this.range > 3) days = 120;
      if (this.range > 10) days = 180;
      return days;
    },

    coupon() {
      let couponqty = 10;
      if (this.range > 3) couponqty = 20;
      if (this.range > 10) couponqty = 30;
      return couponqty;
    },

    totalpricecalculate() {
      let totalprice = 0;

      if (this.range <= 3) {
        totalprice = this.range * this.price13;
      }

      if (this.range > 3) {
        totalprice = this.price49 * (this.range - 3) + 3 * this.price13;
      }

      if (this.range > 10) {
        totalprice =
          this.price1020 * (this.range - 9) +
          3 * this.price13 +
          6 * this.price49;
      }

      if (this.online === "true") totalprice += this.onlineprice;

      return totalprice;
    },
  },

  methods: {
    sendForm(e) {
      axios
        .post("https://arda.hu/api/send_form_products_magento1.php", {
          name: e.name,
          email: e.email,
          phone_number: e.phone_number,
          when: e.when,
          licences: e.licences,
          freedays: e.freedays,
          coupon: e.coupon,
          online: e.online,
          message: e.message,
          accept: e.accept,
          totalprice: e.totalprice,
        })
        .then(() => {
          this.sent = true;
        });
    },

    onReset() {
      this.sent = false;
    },
  },
};
</script>
